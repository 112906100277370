import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Home from "./components/home/home.jsx";
import Listing from "./components/home/listing.jsx";
import Inner from "./components/home/Inner.jsx";
import AkandiVeedu from "./components/home/akkandiVeddu.jsx";
import Contact from "./components/home/contact.jsx";
import { Route, RouterProvider, createBrowserRouter ,createRoutesFromElements} from 'react-router-dom';
import BritishHouse from './components/home/british.jsx';
import PurpleOrchid from "./components/home/purple.jsx";
import AframeCabin from "./components/home/aFrameCabin.jsx";
import CabinView from './components/home/cabinView.jsx';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App/>}>
        <Route path='/' element={<Home/>}/>
        <Route path='/listing' element={<Listing/>}/>
        <Route path='/domestay' element={<Inner/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/akandiveedu' element={<AkandiVeedu/>}/>
        <Route path='/britishhouse' element={<BritishHouse/>}/>
        <Route path='/purpleorchid' element={<PurpleOrchid/>}/>
        <Route path='/aframecabin' element={<AframeCabin/>}/>
        <Route path='/cabinview' element={<CabinView/>}/>
    </Route>
    
  
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router }/>,
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

