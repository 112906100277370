import React, { Component } from 'react';
import "./detailCard.scss";
import R_arrow from "../../assets/image/right-arrow.png";
import User from "../../assets/image/user.svg";
import Room from "../../assets/image/room.png";
import ImageViewer from "react-simple-image-viewer";
import Bed from "../../assets/image/double-bed.png";
import House from "../../assets/image/house-size.png";
import Eyeview from "../../assets/image/eye.png";
import users from "../../assets/image/people.png";
import foods from "../../assets/image/food-b.svg";
import AkandiveeduPopup from "./akandiveeduPopup";
import AkandiPopup from "./AkandiPopup";
import Kingbed from "../../assets/image/kingbed.svg";
import SingleBed from "../../assets/image/single_bed.svg";
import Bathtub from "../../assets/image/bathtub.svg";
import NoSmoking from "../../assets/image/smoke_free.svg";
import Wifi from "../../assets/image/wifi.svg";
import Service from "../../assets/image/support.svg";
import Coffee from "../../assets/image/coffee_maker.svg";
import Toiletries from "../../assets/image/toiletries.svg";
import Hairdryer from "../../assets/image/shower.svg";
import Bathrobe from "../../assets/image/bathrobe.svg";
import Alarm from "../../assets/image/notifications.svg";
import Restuarant from "../../assets/image/flatware.svg";
import GoogleMap from './GoogleMap';
import Akkandi from "../../assets/image/a-veedu.jpg";
import Veedu from "../../assets/image/x-stay.jpg";
import Studio from "../../assets/image/1W3A2039.jpg";
import Bedroom from "../../assets/image/1W3A1999.jpg";
import Living from "../../assets/image/1W3A1678.jpg";
import { Link } from 'react-router-dom';
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Left from "../../assets/image/l-arrow.svg";
import Right from "../../assets/image/r-arrow.svg";
import PropTypes from "prop-types"

const faceContent = [
    {
        id: 1,
        icon: require("../../assets/image/x-stay.jpg"),
        
    },
    {
        id: 2,
        icon: require("../../assets/image/1W3A1709.jpg"),
        
    },
    {
        id: 3,
        icon: require("../../assets/image/1W3A1678.jpg"),
        
    },
    {
      id: 4,
      icon: require("../../assets/image/a-k-veedu.jpg"),
      
    },
    {
        id: 5,
        icon: require("../../assets/image/1W3A2058.jpg"),
        
    },
    {
      id: 5,
      icon: require("../../assets/image/1W3A1948.jpg"),
      
  },
  ];
class AkandiVeedu extends Component {
  constructor(props) {
    super(props);
    this.state = {
        currentImage: 0,
        isViewerOpen: false,
        isPopupOpen: false,
      isDomePopupOpen: false,
        images: [
            Akkandi,
            Veedu,
            Bedroom,
            Living
        ]
    };
}

openImageViewer = (index) => {
    this.setState({
        currentImage: index,
        isViewerOpen: true
    });
};

closeImageViewer = () => {
    this.setState({
        currentImage: 0,
        isViewerOpen: false
    });
};

openPopup = () => {
    this.setState({isPopupOpen: !this.state.isPopupOpen, });
    
  };

  domeOpenPopup = () => {
    this.setState({isDomePopupOpen: !this.state.isDomePopupOpen, });
  }

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  domeClosePopup = () => {
    this.setState({ isDomePopupOpen: false });
}; 
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 3000,
            
           arrows : true,
            
            
          };
        const {isPopupOpen,isDomePopupOpen}= this.state;
        return (
            
               <div className="container-I mr-top">
                    <div className="container">
                        {/*<div className="list-d">
                            <ul>
                                <li>
                                <p>Home</p>
                                <img src={R_arrow} className='R-arrow' alt="r-arrow" />
                                </li>
                                <li>
                                <p>Kodaikanal</p>
                                <img src={R_arrow} className='R-arrow' alt="r-arrow" />
                                </li>
                                <li>
                                <p>Xperential Stay</p>
                                </li>
                            </ul>
                        </div>*/}
                        <div className="list-container">
                          <div className="list-flex">
                          <div className="picture-1">
                              <div >
                                <img 
                                  src={Akkandi} 
                                  alt="" 
                                  className="p-one"
                                  onClick={() => this.openImageViewer(0)}
                                  />
                              </div>
                          </div>
                          <div className="picture-2">
                              
                                <img 
                                  src={Veedu} 
                                  alt="" 
                                  className="p-two"
                                  onClick={() => this.openImageViewer(1)}
                                  />
                              
                                <img 
                                  src={Bedroom} 
                                  alt="" 
                                  className="p-three" 
                                  onClick={() => this.openImageViewer(2)}
                                  />
                            
                                <img 
                                  src={Living} 
                                  alt="" 
                                  className="p-four"
                                  onClick={() => this.openImageViewer(3)}
                                  />
                             
                              <div className="p-map">
                              <GoogleMap/>
                              </div>
                          </div>

                          </div>
                            <div className="list-flex">
                                <div className="r-msg">
                                    <h1>Akkandi Veedu</h1>
                                    <p>Chettiyar park Rd, Kodaikanal.</p>
                                    <h4>⭐ 4.5</h4>
                                    <p>Most remarkable to us was hotel staff from security, doorman, concierge, front office ,restaurants were pleasant, courteous, well trained and attentive.Big thanks to helpful desk executive Ms. Triparna who could fully respond to our requests for assistance promptly and beyond our expectations. To confirm our comments here, we will come back again soon.</p>
                                </div>
                                <div className="pay">
                                    <div className="count-d">
                                        <div className="column-1">
                                            <label htmlFor="checkIn"> Check-in</label>
                                            <input type="text" className='from-control' value="12 pm"/>
                                        </div>
                                        <div className="column-2">
                                            <label htmlFor="checkIn"> Check-out</label>
                                            <input type="text" className='from-control' value= "11 pm"/>
                                        </div>
                                        <div className="column-3">
                                            <div className="point-1">
                                                <img src={User} alt="" className='room'/>
                                                <p>2 x Guests</p>
                                            </div>
                                            <div className="point-2">
                                                <img src={Kingbed} className='room' alt="" />
                                                <p>1 x Room</p>
                                            </div>
                                        </div>

                                    </div>
                                    
                                </div>
                            </div>
                        <div className="amenities">
                            <div className="detail-card">
                            <div className="detail-img">
                            <div className="container-s">
                <div className="content-slick">
                  <div className="container-slick-home-1" >
<img className='Left-arrow' src={Left} alt="" />
                    <Slider {...settings}>

                    {faceContent.map((fc) => (
                      <div className='box-slick-2' key={ fc.id} >
                        
                            <img  className="carousel-img-1" src={fc.icon} alt="" />
                        
                        
                      </div>
                    ))}
                    </Slider>
                    <img className='Right-arrow' src={Right} alt="" />
                  </div>
                </div> 
              </div>
                            </div>
                            <div className="details-contents">
                            <h3>Patio Villa View</h3>
                            <div className="amenties-list">
                            <div className="a-list">
                            <img src={House} alt="" className='house-img' />
                            <p>250&130 Sq.ft</p>
                            </div>
                            <div className="a-list">
                            <img src={Bed} alt="" />
                            <p>Double bed</p>
                            </div>
                            <div className="a-list">
                            <img src={users} alt="" className='house-img'/>
                            <p>Max 4+2 Guests</p>
                            </div>
                            <div className="a-list">
                            <img src={Eyeview} alt="" />
                            <p>Valley view</p>
                            </div>
                            <span>
                            <img src={foods} alt="" />
                            <p>Free Breakfast Included</p>
                            </span>
                            </div>
                            

                            <button
                  type='button'
                  onClick={this.domeOpenPopup}
                  > View More Details
                  </button>
                            </div>
                            
                            </div>
                            <button className='book-btn-card'><a href="https://www.secure-booking-engine.com/accounts/v99jfOkR03dXYzHQhpItgg/properties/hLbhZCq-M8Rj7opE4olhbQ/booking-engine/web/source/4wsctBw6Oq6j-g9XuxeRzQ/">BOOK NOW</a></button>
                        </div>
                        <div className="amenities mr-bottom">
                            <div className="detail-card">
                            <div className="detail-img">
                            <img src={Studio} alt="" />
                            </div>
                            <div className="details-contents">
                            <h3>Studio Villa View</h3>
                            <div className="amenties-list">
                            <div className="a-list">
                            <img src={House} alt="" className='house-img' />
                            <p>250&130 Sq.ft</p>
                            </div>
                            <div className="a-list">
                            <img src={Bed} alt="" />
                            <p>Double bed</p>
                            </div>
                            <div className="a-list">
                            <img src={users} alt="" className='house-img'/>
                            <p>Max 4+2 Guests</p>
                            </div>
                            <div className="a-list">
                            <img src={Eyeview} alt="" />
                            <p>Valley view</p>
                            </div>
                            <span>
                            <img src={foods} alt="" />
                            <p>Free Breakfast Included</p>
                            </span>
                            </div>
                            

                            <button
                  type='button'
                  onClick={this.openPopup}
                  > View More Details
                  </button>
                            </div>
                            
                            </div>
                            <button className='book-btn-card'><a href="https://www.secure-booking-engine.com/accounts/v99jfOkR03dXYzHQhpItgg/properties/hLbhZCq-M8Rj7opE4olhbQ/booking-engine/web/source/4wsctBw6Oq6j-g9XuxeRzQ/">BOOK NOW</a></button>
                        </div>
                        </div>

                    </div>
                    {this.state.isViewerOpen && (
                      <ImageViewer
                          src={this.state.images}
                          currentIndex={this.state.currentImage}
                          onClose={this.closeImageViewer}
                          disableScroll={false}
                          backgroundStyle={{
                              backgroundColor: "rgba(0,0,0,0.9)"
                          }}
                          closeOnClickOutside={true}
                      />
                  )}
                  {isPopupOpen && (
                    <AkandiveeduPopup 
                    
                    close={this.closePopup}
                    showing={this.state.isPopupOpen}
                    />
                  )}
                  {isDomePopupOpen && (
                    <AkandiPopup 
                    
                    close={this.domeClosePopup}
                    showing={this.state.isDomePopupOpen}
                    />
                  )}
               </div> 
           
        );
    }
}
AkandiVeedu.propTypes = {
    faceContent: PropTypes.array.isRequired
    };
export default AkandiVeedu;